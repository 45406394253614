// No se deben usar "_" para los nombres de las URLs

export const URLS = {
  COMMONS: {
    CHOICES: {
      EQUIPMENTTYPE: { LIST: "v2/commons/choices/equipment_type/" },
      TRANSPORTTYPE: { LIST: "v2/commons/choices/transport_type/" },
    },
  },
  ORDER: {
    USERS: {
      LIST: 'v2/order/{order_id}/get_users/?name={name}'
    }
  },
  COMPANIES: {
    BIBUSINESSINTELLIGENCE:{
      LIST: "v2/companies/{company_id}/bi_business_intelligence/",
      DELETE: "v2/companies/{company_id}/bi_business_intelligence/{configuration_id}/",
      POST: "v2/companies/{company_id}/bi_business_intelligence/",
      DETAIL: {
        GET: "v2/companies/{company_id}/bi_business_intelligence/{configuration_id}/",
        PATCH: "v2/companies/{company_id}/bi_business_intelligence/{configuration_id}/"
      }
    },
    REPORTS:{
      CFDI: {
        LIST: 'v2/companies/{company_id}/cfdi_report/?dates={dates}'
      },
      PLATES: {
        LIST: "v2/companies/{company_id}/gps_activities_2?view={loadType}&in_last_hours={in_last_hours}&start_date={start_date}&end_date={end_date}&plates={plates}"
      },
      FILTERS: {
        GET: 'v2/companies/{company_id}/gps_activities/?date=&view=filters'
      }
    },
    TAXINFO: {
      FACILITY: {
        GET: 'v2/companies/{company_id}/facilities/{id}/tax_info/',
        POST: 'v2/companies/{company_id}/facilities/{id}/tax_info/',
        DELETE: 'v2/companies/{company_id}/facilities/{id}/tax_info/{id_to_delete}/',
      },
      GET: 'v2/companies/{company_id}/tax_info/'
    },
    RETRIEVE: {
      GET: "v2/companies/{company_id}/"
    },
    CREATE: {
      POST: "v2/companies/",
      VALIDATIONS: {
        COMPANYNAME: {
          GET: "v2/companies/?corporation_name={corporation_name}"
        }
      }
    },
    UPDATE: {
      PATCH: "v2/companies/{company_id}/"
    },
    USERTYPE: {
      PATCH:
        "v2/companies/{company_id}/companies_users/{id}/?action={action}",
    },
    GPSACTIVITY: {
      REPORT: {
        LIST: 'v2/companies/{company_id}/gps_activities/?date={date}&view={view}&assemblies={assemblies}&contracts={contracts}&transport_lines={transport_lines}&search={search}'
      },
      FILTERS: {
        LIST: 'v2/companies/{company_id}/gps_activities/?date=&view=filters'
      },
      COLORS: {
        LIST: 'v2/commons/catalogs/gps_colors/'
      },
      GET: "v2/companies/{company_id}/gps_activities/{id}/"
    },
    PRODUCTS: {
      LIST: "v2/companies/{company_id}/products/?view={view}&tags={tags}&skus={skus}&products={products}&search={search}&limit={limit}&offset={offset}",
    },
    ALERTS: {
      PATCH: "v2/companies/{company_id}/alerts/{alert_id}/", //is_active
      CATALOG: {
        LIST: "v2/companies/{company_id}/alerts/"
      },
      DASHBOARD: {
        GLOBAL: {
          LIST: "v2/companies/{company_id}/dashboard_alerts/?view=global&is_active={is_active}&start_date={start_date}&end_date={end_date}&facilities={facilities}&order_tags={order_tags}&trip_tags={trip_tags}&alerts={alerts}&level={level}&fleet_provider={fleet_provider}"
        },
        DESGLOSEALERTAS: {
          LIST: "v2/companies/{company_id}/dashboard_alerts/?view=desglose_alertas&is_active={is_active}&start_date={start_date}&end_date={end_date}&facilities={facilities}&order_tags={order_tags}&trip_tags={trip_tags}&alerts={alerts}&level={level}&fleet_provider={fleet_provider}"
        },
        CRITERIO: {
          LIST: "v2/companies/{company_id}/dashboard_alerts/?view=criterio&is_active={is_active}&start_date={start_date}&end_date={end_date}&group={group}&facilities={facilities}&facilities_tags={facilities_tags}&order_tags={order_tags}&trip_tags={trip_tags}&alerts={alerts}&level={level}&fleet_provider={fleet_provider}&route={ruta}"
        }
      },
      GROUPS : {
        LIST: "v2/companies/{company_id}/alerts/{alert_id}/groups/",
        POST: "v2/companies/{company_id}/alerts/{alert_id}/groups/",
        DELETE: "v2/companies/{company_id}/alerts/{alert_id}/groups/{group_id}/"
      },
      BASE: {
        PATCH: "v2/companies/{company_id}/alerts/{alert_id}/", //is_active
        GROUPS : {
          LIST: "v2/companies/{company_id}/alert_base/{alert_id}/groups/",
          POST: "v2/companies/{company_id}/alert_base/{alert_id}/groups/",
          DELETE: "v2/companies/{company_id}/alert_base/{alert_id}/groups/{group_id}/"
        },
      },
      NOTIFICATIONCENTER: {
        LIST: "v2/companies/{company_id}/companies_users/{user_company}/notification_center/?action=&limit={limit}&offset={offset}&date={date}&status={status}&level={level}&attended={attended}&search={search}&alert_type={type}",
        ATTENDED: {
          GET: "v2/companies/{company_id}/companies_users/{company_user}/notification_center/?action=attend&id={alert_id}&set={attended}"
        },
        DELETED: {
          GET: "v2/companies/{company_id}/companies_users/{company_user}/notification_center/?action=delete&id={alert_id}"
        },
        CLOSED: {
          GET: "v2/companies/{company_id}/companies_users/{company_user}/notification_center/?action=close&id={alert_id}"
        },
        UNSUBSCRIBED: {
          GET: "v2/companies/{company_id}/companies_users/{company_user}/notification_center/?action=unlink&id={alert_id}"
        }
      }
    },
    APPOINTMENTCONTROL: {
      UPLOAD: {
        POST: "v2/companies/{company_id}/appointment_control/upload"
      }
    },
    BRIDGE: {
      CATALOG: {
        LIST: "v2/companies/{company_id}/bridge/?page_size={limit}&page_num={offset}"
      },
      LOGS: {
        LIST: "v2/companies/{company_id}/bridge/logs/{bridge_name}/?page_size={limit}&page_num={offset}&search={search}&search_param={search_param}",
      },
      TRANSACTIONS: {
        LIST: "v2/companies/{company_id}/bridge/logs/{bridge_name}/?page_size={limit}&page_num={offset}&object_id={transaction_id}",
      }
    },
    CATALOGS: {
      VEHICLE: {
        LIST: "v2/companies/{company_id}/fleet_catalog/1/?view=filters&economic_number=&plates=&name=&search="
      },
      EQUIPMENT: {
        LIST: "v2/companies/{company_id}/fleet_catalog/2/?view=filters&economic_number=&plates=&name=&search="
      },
      FAQS: { LIST: "v2/companies/{company_id}/faqs/" },
      ALERTCOUNTER: { 
        LIST: "v2/companies/{company_id}/companies_users/{user_company}/notification_center/?action={action}" ,
        DETAIL: {
          LIST: "v2/companies/{company_id}/companies_users/{user_company}/notification_center/?action={action}"
        }
      },
      ALERTSTATUS: { LIST : "v2/companies/{company_id}/alerts/status/" },
      ALERTTYPES: { LIST : "v2/companies/{company_id}/alerts/" },
      VEHICLETYPES: { LIST: "v2/companies/catalogs/vehicle_type/" },
      FLEETCLASSES: { LIST: "v2/companies/catalogs/fleet_class/" },
      OPERATORLICENSETYPES: {
        LIST: "v2/companies/catalogs/operator_licence_type/",
      },
      TRIPSTATUS: { LIST: "v2/companies/catalogs/trip_status/" },
      ORDERSTATUS: { LIST: "v2/companies/catalogs/order_status/" },
      ORDERSMOTIVES: { LIST: "v2/companies/catalogs/orders_motives/" },
      TRIPCARDEVENTS: { LIST: "v2/companies/catalogs/trip_card_events/" },
      CONTAINERSTATUS: { LIST: "v2/companies/catalogs/container_status/" },
      GPSZONETYPES: { LIST: "v2/commons/catalogs/gps_zone_type/" },
      FACILITYAVATAR: { LIST: "v1/public/avatar/" },
      DEVICES: { LIST: "v2/companies/catalogs/devices/" },
      UNITS: { LIST: "v2/companies/{company_id}/units/" }
    },
    AVAILABILITY: {
      REPORT: {
        LIST: "v2/companies/{company_id}/availability/?view=report"
      },
      LOCATION: {
        LIST: "v2/companies/{company_id}/availability/{id}/?action=location"
      }
    },
    CONTRACTS: {
      LIST:
        "v2/companies/{company_id}/contracts/?view=catalog&limit={limit}&offset={offset}&facility={facilities}&transportclass={transportClass}&network={network}&search={search}",
      ADDENDUMS: {
        PUT:
          "v2/companies/{company_id}/contracts/{contract_id}/addendums/{addendum_id}/",
      },
      SLOTS: {
        LIST:
          "v2/companies/{company_id}/contracts/{contract_id}/slots/?start_date={start_date}&end_date={end_date}",
        PUT:
          "v2/companies/{company_id}/contracts/{contract_id}/slots/{slot_id}/",
        ASSEMBLIES: {
          DELETE:
            "v2/companies/{company_id}/contracts/{contract_id}/slots/{slot_id}/",
          PUT:
            "v2/companies/{company_id}/contracts/{contract_id}/slots/{slot_id}/",
        },
      },
      CLASSES: {
        LIST: "v2/companies/{company_id}/contracts/{contract_id}/classes/",
        POST: "v2/companies/{company_id}/contracts/{contract_id}/classes/",
      },
      ASSEMBLIES: {
        LIST: "v2/companies/{company_id}/contracts/{contract_id}/assemblies/?search={search}",
      },
      FILTERS: {
        FACILITIES: {
          LIST: "v2/companies/{company_id}/contracts/?view=facilities",
        },
        CLASSES: {
          LIST: "v2/companies/{company_id}/contracts/?view=classes",
        },
      },
    },

    FACILITIES: {
      LIST: "v2/companies/{company_id}/facilities/?view=short_detail",
      GET: "v2/companies/{company_id}/facilities/{id}/",
      CREATE: {
        POST: "v2/companies/{company_id}/facilities/"
      },
      UPDATE: {
        PATCH: "v2/companies/{company_id}/facilities/{facility_id}/"
      },
      FILTERS: {
        LIST: "v2/companies/{company_id}/facilities/?view={view}",
      },
      DEFAULTTIME: {
        GET: "v2/companies/{company_id}/facilities/{facility_id}/optime/",
        PATCH: "v2/companies/{company_id}/facilities/{facility_id}/optime/"
      },
      CONTRACTS: {
        LIST: "v2/companies/{company_id}/facilities/{facility_id}/contracts/",
      },
      CONTROL: {
        SUMMARY: {
          LIST:
            "v2/companies/{company_id}/facility_control/?view={view}&date={date}&facilities={facilities}&tags={tags}",
          CAPACITY: {
            LIST:
              "v2/companies/{company_id}/facility_control/{facility_id}/?date={date}",
            LIMITS: {
              POST:
                "v2/companies/{company_id}/facility_control/{facility_id}/limited_operations/",
              PATCH:
                "v2/companies/{company_id}/facility_control/{facility_id}/limited_operations/{limit_id}/",
              VALIDATION: {
                GET:
                  "v2/companies/{company_id}/facility_control/{facility_id}/limited_operations/{limit_id}/?validate={validation}",
              },
              APPLICATION: {
                GET:
                  "v2/companies/{company_id}/facility_control/{facility_id}/limited_operations/{limit_id}/?apply={application}",
              },
            },
          },
        },
      },
      ROUTESDETAIL: {
        INFO: {
          GET: "v2/companies/{company_id}/facilities/{facility_id}/routes_detail/"
        },
        PUT: "v2/companies/{company_id}/facilities/{facility_id}/routes_detail/"
      },
      SPACES: {
        LIST:
          "v2/companies/{company_id}/facilities/{facility_id}/spaces/?view={view}&date={date}",
        SCHEDULE: {
          LIST:
            "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/schedule/",
        },
        SIMPLE: {
          LIST: "v2/companies/{company_id}/facilities/{facility_id}/spaces/?view=booking",
        },
        DATA: {
          GET: "v2/companies/{company_id}/facilities/{facility_id}/spaces/?view=appointments&date={date}&operation={operation}&order_id={order_id}",
        },
        ERASE: {
          DELETE: "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/"
        },
        WINDOWS: {
          POST: "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows_v2/",
          DELETE: "v2/companies/{company_id}/facilities/{facility_id}/spaces/{space_id}/windows_v2/{window_id}/"
        }
      },
      ORDERSCONTROL: {
        LIST:
          "v2/companies/{company_id}/facilities/{facility_id}/orders_control/?view={view}&date={date}&status={status}&fleet_providers={fleet_providers}&transport_classes={transport_classes}&trip_tags={trip_tags}&order_tags={order_tags}&facilities={facilities}&priority={priority}&category={category}&dashboard={dashboard}&timezone={timezone}",
        GET:
          "v2/companies/{company_id}/facilities/{facility_id}/orders_control/{order_id}/",
        ORDER: {
          PATCH: "v2/companies/{company_id}/orders/{order_id}/", //code
          TAGS: {
            POST: "v2/companies/{company_id}/orders/{order_id}/order_tag/", //order, tag
            DELETE:
              "v2/companies/{company_id}/orders/{order_id}/order_tag/{order_tag_id}",
          },
          HISTORY: {
            GET: "v2/companies/{company_id}/orders/{order_id}/?view=historical",
            ELIMINATE: {
              PATCH: "v2/companies/{company_id}/orders/{order_id}/?view=&action=eliminate_event", //microtip_id, eliminate_all
            }
          },
          LOCATIONS: {
            GET: "v2/companies/{company_id}/orders/{order_id}/?view=locations"
          },
          ROUTE: {
            LIST: "v2/companies/{company_id}/orders/{order_id}/?view=route_map_zones"
          },
          COMMENTS: {
            LIST: "v2/companies/{company_id}/orders/{order_id}/comments/",
            POST: "v2/companies/{company_id}/orders/{order_id}/comments/",
            FAVORITES: {
              LIST: "v2/companies/{company_id}/companies_users/{company_user}/favorite_comments/",
              POST: "v2/companies/{company_id}/companies_users/{company_user}/favorite_comments/",//comment
              PATCH: "v2/companies/{company_id}/companies_users/{company_user}/favorite_comments/{comment_id}/",
              DELETE: "v2/companies/{company_id}/companies_users/{company_user}/favorite_comments/{comment_id}/"
            }
          }
        },
        TRIP: {
          PATCH: "v2/companies/{company_id}/trips/{trip_id}/", //signature, transport_class
          CONTROLLED: {
            PATCH: "v2/companies/{company_id}/trips/{trip_id}/?action=update_trip_details",
          },
          TAGS: {
            POST: "v2/companies/{company_id}/trips/{trip_id}/tag/", //trip, tag
            DELETE:
              "v2/companies/{company_id}/trips/{trip_id}/tag/{trip_tag_id}/",
          },
        },
      },
      COMMANDCONTROLCO: {
        //https://qa.v2.orbinetwork.com/api/v2/companies/61/shipment_control/?view=all&offset=1&limit=162&appointment=&appointment_type=1,2,3&multifacilities=7246,6937,7336,7848,7465,6874,7808,7807,7804,7803,7761,7760,7539,7516,7366,7273,6898&status=&appointment_status=&fleet_providers=37&transport_classes=&trip_tags=&order_tags=&priority=&search=&search_type=&category=3-1&start_date=2022-02-24T23:15:59.500Z&end_date=2022-03-24T23:15:59.500Z
        //"v2/companies/61/shipment_control/?view=all&offset=1&limit=162&appointment=&appointment_type=1,2,3&multifacilities=7246,6937,7336,7848,7465,6874,7808,7807,7804,7803,7761,7760,7539,7516,7366,7273,6898&status=&appointment_status=&fleet_providers=37&transport_classes=&trip_tags=&order_tags=&priority=&search=&search_type=&category=3-1&start_date=2022-02-24T23:15:59.500Z&end_date=2022-03-24T23:15:59.500Z"
        LIST: "v2/companies/{company_id}/shipment_control/?view={view}&offset={offset}&limit={limit}&appointment={appointment}&appointment_type={appointment_type}&multifacilities={multifacilities}&from_facilities={from_facilities}&to_facilities={to_facilities}&status={status}&appointment_status={appointment_status}&fleet_providers={fleet_providers}&transport_classes={transport_classes}&trip_tags={trip_tags}&order_tags={order_tags}&priority={priority}&search={search}&search_type={search_type}&category={category}&start_date={start_date}&end_date={end_date}&timezone={timezone}&facilities={facilities}&container_status={container_status}&eta_load_sort={eta_load_sort}&eta_unload_sort={eta_unload_sort}",
        EVENTS: {
          LIST: "v2/companies/{company_id}/shipment_control/{order_id}/"
        }
      },
      COMMANDCONTROLLT: {
        LIST: "v2/companies/{company_id}/shipment_control/?view={view}&appointment={appointment}&appointment_type={appointment_type}&status={status}&order_status={orderStatus}&base_tags={baseTags}&vehicle={vehicle}&fleet_providers={fleet_providers}&from_facilities={from_facilities}&to_facilities={to_facilities}&from_to_facilities={from_to_facilities}&limit={limit}&offset={offset}&search={search}&transport_classes={transport_classes}&action={action}&search_type={search_type}&timezone={timezone}&container_status={container_status}&eta_load_sort={eta_load_sort}&eta_unload_sort={eta_unload_sort}&priority={priority}&order_tags={order_tags}",
      },
      TRIPSCONTROL: {
        LIST:
          "v2/companies/{company_id}/facilities/{facility_id}/trips_control/?view={view}&offset={offset}&limit={limit}&appointment={appointment}&appointment_type={appointment_type}&multifacilities={multifacilities}&from_facilities={from_facilities}&to_facilities={to_facilities}&status={status}&appointment_status={appointment_status}&fleet_providers={fleet_providers}&transport_classes={transport_classes}&trip_tags={trip_tags}&order_tags={order_tags}&facilities={facilities}&priority={priority}&search={search}&search_type={search_type}&category={category}&timezone={timezone}",
        BOOKING: {
          LIST:
            "v2/companies/{company_id}/facilities/{facility_id}/trips_control/{trip_id}/?view={view}",
        },
        DOWNLOAD: {
          GET: "v2/companies/{company_id}/facilities/{facility_id}/trips_control/?download=true&view={view}&offset={offset}&limit={limit}&appointment={appointment}&appointment_type={appointment_type}&multifacilities={multifacilities}&status={status}&appointment_status={appointment_status}&fleet_providers={fleet_providers}&transport_classes={transport_classes}&trip_tags={trip_tags}&order_tags={order_tags}&facilities={facilities}&priority={priority}&search={search}"
        }
      },
    },
    FLEETCATALOG: {
      LIST:
        "v2/companies/{company_id}/fleet_catalog/{type_id}/?view={view}&economic_number={economic_number}&plates={plates}&name={name}&search={search}",
      COUNTERS: {
        GET: "v2/companies/{company_id}/fleet_catalog/",
      }
    },
    FLEETS: {
      LIST: "v2/companies/{company_id}/fleets/",
      ASSEMBLIES: {
        LIST:
          "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/?limit={limit}&offset={offset}&view={view}&previous_assemblies={previous_assemblies}&operator_status={operator_status}&operator_license_type={operator_license_type}&equipment_status={equipment_status}&equipment_class={equipment_class}&equipment_type={equipment_type}&vehicle_status={vehicle_status}&vehicle_class={vehicle_class}&vehicle_type={vehicle_type}&vehicle_tags={vehicle_tags}&equipment_tags={equipment_tags}&operator_tags={operator_tags}&start_date={start_date}&end_date={end_date}&get_trips={get_trips}&status={status}&order_status={orderStatus}&base_tags={baseTags}&fleet_providers={fleet_provider}&from_to_companies={from_to_companies}&from_facilities={from_facilities}&to_facilities={to_facilities}&from_to_facilities={from_to_facilities}&search={search}&vehicles={vehicle}&equipments={equipment}",
        GET: "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{id}/",
        POST: "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/",
        ACTIONS: {
          GET: "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/?action=get_actions"
        },
        CREATION: {
          POST: "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/",
          DELETE: "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assemble_id}/?action=disassemble"
        },
        EQUIPMENTS: {
          LIST:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/equipment/",
          GET:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/equipment/{id}/",
          POST:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/equipment/",
        },
        OPERATORS: {
          LIST:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/operator/",
          GET:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/operator/{id}/",
          POST:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/operator/",
        },
        VEHICLES: {
          LIST:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/vehicle/",
          GET:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/vehicle/{id}/",
          POST:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/vehicle/",
        },
        OPERATORLOGS: {
          DELETE:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/operator_log/{id}/",
          PUT:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/operator_log/{id}/",
        },
        EQUIPMENTLOGS: {
          DELETE:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/equipment_log/{id}/",
          PUT:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/equipment_log/{id}/",
        },
        VEHICLELOGS: {
          DELETE:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/vehicle_log/{id}/",
          PUT:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/vehicle_log/{id}/",
        },
        FILTERS: {
          LIST:
            "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/?view={view}&start_date={start_date}&end_date={end_date}&get_trips={get_trips}&action={action}",
        },
        SINGLE: {
          LIST: "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/?limit={limit}&offset={offset}&view=single",
          JOINED: {
            PATCH: "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/?action=merge", //merged_assembly
          }
        }
      },
      CATALOG: {
        EQUIPMENTS: {
          LIST: "v2/companies/{company_id}/fleets/catalog/{type_id}/?page={page}&limit={limit}&tags={tags}&status={status}&companies={companies}&type={type}&gps={gps}&text={text}",
        },
        VEHICLES: {
          LIST: "v2/companies/{company_id}/fleets/catalog/{type_id}/?page={page}&limit={limit}&tags={tags}&status={status}&companies={companies}&type={type}&gps={gps}&text={text}",
        },
        OPERATORS: {
          LIST: "v2/companies/{company_id}/fleets/catalog/{type_id}/?page={page}&limit={limit}&tags={tags}&status={status}&companies={companies}&type={type}&gps={gps}&text={text}",
        },
        OPERATORSV2: {
          CO: {
            LIST: "v2/companies/{company_id}/fleets/fleet-catalog-operators-v2/?page={page}&limit={limit}&tags={tags}&status={status}&companies={companies}&type={type}&gps={gps}&text={text}&view={view}",
          },
          TR: {
            LIST: "v2/companies/{company_id}/fleets/{fleet_id}/catalog-operators-v2/?page={page}&limit={limit}&tags={tags}&status={status}&companies={companies}&type={type}&gps={gps}&text={text}&view={view}"
          }
        },
        STATUSLOG: {
          LIST: "v2/companies/{company_id}/fleets/catalog/{type_id}/?view=statuslog&page={page}&limit={limit}&tags={tags}&status={status}&companies={companies}&type={type}&gps={gps}&text={text}",
        },
        MOTIVES: {
          LIST: "v2/companies/{company_id}/fleets/catalog/{type}/?view=motives",
        },
      },
      DATA: {
        LIST: "v2/companies/{company_id}/fleets/{fleet_id}/data/",
        DELETE: "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/",
        OPERATORS: {
          POST: "v2/companies/{company_id}/fleets/{fleet_id}/operators_v2/",
          PATCH: "v2/companies/{company_id}/fleets/{fleet_id}/operators_v2/{data_id}/",
          DELETE: "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/normativities/{normativity_id}/images/{file_id}/",
          UNSHARE: {
            DELETE: "v2/companies/{company_id}/fleet_provider/{fleet_provider}/share_operators/{operator_id}/"
          },
          CLONING: {
            POST: "v2/companies/{company_id}/fleets/{fleet_id}/operators/{data_id}/normativities/clone/"
          },
          UPDATESTATUS: {
            POST: "v2/companies/{company_id}/fleets/{fleet_id}/operators/{data_id}/normativities/status-update/"
          }
        },
        VEHICLES: {
          POST: "v2/companies/{company_id}/fleets/{fleet_id}/data_v2/",
          PATCH: "v2/companies/{company_id}/fleets/{fleet_id}/data_v2/{data_id}/",
          UNSHARE: {
            DELETE: "v2/companies/{company_id}/fleet_provider/{fleet_provider}/vehicles/{vehicle_id}/"
          }
        },
        EQUIPMENTS: {
          POST: "v2/companies/{company_id}/fleets/{fleet_id}/data_v2/",
          PATCH: "v2/companies/{company_id}/fleets/{fleet_id}/data_v2/{data_id}/",
          UNSHARE: {
            DELETE: "v2/companies/{company_id}/fleet_provider/{fleet_provider}/equipments/{equipment_id}/"
          }
        },
        UNSHARE: {
          DELETE: "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/unshare/{company_to_unshare}/{type}/",
        }
      },
      OPERATORS: {
        LIST: "v2/companies/{company_id}/fleets/{fleet_id}/operators/",
        DELETE: "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/",
        INVITATIONS: {
          POST: "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/invitation/"
        },
        UNSHARE: {
          DELETE: "v2/companies/{company_id}/fleet_provider/{provider_id}/operators/{operator_id}/unshare/{company_to_unshare}/",
        }
      },
      RESOURCES: {
        COUNTER: {
          LIST: "v2/companies/{company_id}/fleets/{fleet_id}/resource_control/{type}/?start_date={start_date}&end_date={end_date}&limit={limit}&offset={offset}&search={search}&tags={tags}&status={status}&vehicle_class={vehicle_class}&date_counter={date_counter}",
        },
        VEHICLES: {
          LIST:
            "v2/companies/{company_id}/fleets/{fleet_id}/resource_control/1/?start_date={start_date}&end_date={end_date}&limit={limit}&offset={offset}&search={search}&tags={tags}&status={status}&vehicle_class={vehicle_class}",
          MOTIVES: {
            LIST: "v2/companies/{company_id}/fleets/catalog/1/?view=motives",
          },
          EXEMPTIONS: {
            POST:
              "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/vehicle_controls/", //exeption_from, exception_to, fleet_vehicle, motive
            PATCH:
              "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/vehicle_controls/{exemption_id}/",
            DELETE:
              "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/vehicle_controls/{exemption_id}/",
          },
        },
        EQUIPMENTS: {
          LIST:
            "v2/companies/{company_id}/fleets/{fleet_id}/resource_control/2/?start_date={start_date}&end_date={end_date}&limit={limit}&offset={offset}&search={search}&tags={tags}&status={status}&equipment_class={equipment_class}",
          MOTIVES: {
            LIST: "v2/companies/{company_id}/fleets/catalog/2/?view=motives",
          },
          EXEMPTIONS: {
            POST:
              "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/equipment_controls/", //exeption_from, exception_to, fleet_equipment, motive
            PATCH:
              "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/equipment_controls/{exemption_id}/",
            DELETE:
              "v2/companies/{company_id}/fleets/{fleet_id}/data/{fleet_data_id}/equipment_controls/{exemption_id}/",
          },
        },
        OPERATORS: {
          LIST:
            "v2/companies/{company_id}/fleets/{fleet_id}/resource_control/3/?start_date={start_date}&end_date={end_date}&limit={limit}&offset={offset}&search={search}&tags={tags}&status={status}&operator_license_type={operator_license_type}",
          MOTIVES: {
            LIST: "v2/companies/{company_id}/fleets/catalog/3/?view=motives",
          },
          EXEMPTIONS: {
            POST:
              "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/operator_controls/", //exeption_from, exception_to, fleet_operator, motive
            PATCH:
              "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/operator_controls/{exemption_id}/",
            DELETE:
              "v2/companies/{company_id}/fleets/{fleet_id}/operators/{operator_id}/operator_controls/{exemption_id}/",
          },
        },
      },
    },
    GPS: {
      LIST:
        "v2/companies/{company_id}/gps/?in_facility={in_facilities}&from_to={from_to}&from_facilities={from_facilities}&to_facilities={to_facilities}&status={status}&base={base}&trip_tags={trip_tags}&order_tags={order_tags}&transport_classes={transport_classes}&fleet_providers={fleet_providers}&startDate={startDate}&endDate={endDate}&plates={plates}",
      EVENTS: {
        LIST: 'v2/companies/{company_id}/gps/?in_facility={in_facilities}&from_to={from_to}&from_facilities={from_facilities}&to_facilities={to_facilities}&status={status}&base={base}&trip_tags={trip_tags}&order_tags={order_tags}&transport_classes={transport_classes}&fleet_providers={fleet_providers}&startDate={startDate}&endDate={endDate}&plates={plates}&view=historical_events'
      },
      LOCATIONS: {
        LIST: 'v2/companies/{company_id}/gps/?in_facility={in_facilities}&from_to={from_to}&from_facilities={from_facilities}&to_facilities={to_facilities}&status={status}&base={base}&trip_tags={trip_tags}&order_tags={order_tags}&transport_classes={transport_classes}&fleet_providers={fleet_providers}&startDate={startDate}&endDate={endDate}&plates={plates}&view=historical_locations'
      },
      ZONES: {
        LIST: "v2/companies/{company_id}/gps_map_zones/",
        POST: "v2/companies/{company_id}/gps_map_zones/", //description,company,zone_type,polygon
        PATCH: "v2/companies/{company_id}/gps_map_zones/{zone_id}/",
        DELETE: "v2/companies/{company_id}/gps_map_zones/{zone_id}/",
        DETAIL: {
          LIST: "v2/companies/{company_id}/gps_map_zones/{zone_id}/details/",
          POST: "v2/companies/{company_id}/gps_map_zones/{zone_id}/details/", //map_zone,week_day,hour_from,hour_to,minute_from,map_zone_class
          PATCH: "v2/companies/{company_id}/gps_map_zones/{zone_id}/details/{detail_id}/",
          DELETE: "v2/companies/{company_id}/gps_map_zones/{zone_id}/details/{detail_id}/",
        },
      },
      FACILITIES: {
        LIST: "v2/companies/{company_id}/gps_facilities/",
        SINGLE: {
          LIST: "v2/companies/{company_id}/gps_facilities/?facilities={facilities_id}",
        }
      },
      TRANSPORTFILTERS: {
        LIST: "v2/companies/{company_id}/gps/?get_filters=true"
      }
    },
    HOME: {
      LIST: "v2/companies/{company_id}/home/",
    },
    INVOICES: {
      REPORT: {
        LIST: "v2/companies/{company_id}/invoices/?limit={limit}&offset={offset}&start_date={start_date}&end_date={end_date}&fiscal_number={fiscal_number}",
        FILTERS: {
          RFC: {
            LIST: "v2/companies/{company_id}/tax_info/?zip_file=1"
          }
        }
      }
    },
    KPIS: {
      CATALOGS: {
        TIME: {
          LIST: "v2/companies/{company_id}/kpi/?start_date={start_date}&end_date={end_date}&facilities={facilities}&facilities_tags={facilities_tags}&order_tags={order_tags}&trip_tags={trip_tags}&expedition_class={expedition_class}&operation={operation}&status={status}&supplement={supplement}&fleet_provider={fleet_provider}&kpi_type=1"
        },
        RANGE: {
          LIST: "v2/companies/{company_id}/kpi/?start_date={start_date}&end_date={end_date}&facilities={facilities}&facilities_tags={facilities_tags}&order_tags={order_tags}&trip_tags={trip_tags}&expedition_class={expedition_class}&operation={operation}&status={status}&supplement={supplement}&fleet_provider={fleet_provider}&kpi_type=2"
        },
        INTRANSIT: {
          LIST: "v2/companies/{company_id}/kpi/?start_date={start_date}&end_date={end_date}&facilities={facilities}&facilities_tags={facilities_tags}&order_tags={order_tags}&trip_tags={trip_tags}&expedition_class={expedition_class}&operation={operation}&supplement={supplement}&fleet_provider={fleet_provider}&kpi_type=3"
        }
      },
      DETAIL: {
        LIST: "v2/companies/{company_id}/kpi/?start_date={start_date}&end_date={end_date}&facilities={facilities}&facilities_tags={facilities_tags}&order_tags={order_tags}&trip_tags={trip_tags}&expedition_class={expedition_class}&operation={operation}&status={status}&supplement={supplement}&fleet_provider={fleet_provider}&kpi_type={type}&detail=1"
      }
    },
    NETWORK: {
      LIST: "v2/companies/{company_id}/network/?view={view}",
      FACILITIES: {
        LIST: "v2/companies/{company_id}/network_facilities/"
      },
      RECOMMENDATIONS: {
        LIST: "v2/companies/{company_id}/network/?view=recommendations&limit={limit}&page={offset}&text={search}"
      },
      ACCEPTREQUEST: {
        GET: "v2/companies/{company_id}/network/{id}/?request=accept"
      }
    },
    NOTIFICATIONSV2: {
      CANCELREQUEST: {
        POST: "v2/companies/{company_id}/notifications_v2/order/{order_id}/",
      }
    },
    NOTIFICATIONS: {
      LIST: "v2/companies/{company_id}/notifications_v2/",
      PATCH: "v2/companies/{company_id}/notifications_v2/{notification_id}/details/", //is_active
      DETAILS: {
        LIST: "v2/companies/{company_id}/notifications_v2/{notification_id}/details/",
        POST: "v2/companies/{company_id}/notifications_v2/{notification_id}/details/",
        DELETE: "v2/companies/{company_id}/notifications_v2/group/{group_id}/",
        PATCH: "v2/companies/{company_id}/notifications_v2/group/{group_id}/",
      },
      USERS: {
        LIST: "v2/companies/{company_id}/notifications/{notification_id}/users/",
        POST: "v2/companies/{company_id}/notifications/{notification_id}/users/"
      },
      CONTROL: {
        MESSAGES: {
          LIST: "v2/companies/{company_id}/notifications_v2/user/{user_id}/?view=notifications",
          POST: "v2/companies/{company_id}/notifications_v2/sendNotification/",
          ACTIONS: {
            PATCH: "v2/companies/{company_id}/notifications_v2/user/{user_id}/notification/{notification_id}/",
            DELETE: "v2/companies/{company_id}/notifications_v2/user/{user_id}/notification/{notification_id}/",
          },
          DATA: {
            LIST: "v2/companies/{company_id}/notifications_v2/user/{user_id}/notificationList/?limit={limit}&offset={offset}&date={date}&attended={attended}&search={search}",
          }
        },
        USERS: {
          LIST: "v2/companies/{company_id}/notifications_v2/order/{order_id}/users/?view=users_to_notify&users_id={users_id}&comment_parent={comment_parent}",
        },
        COUNTER: {
          LIST: "v2/companies/{company_id}/notifications_v2/?view=counter&user_id={user_id}",
        }
      }
    },
    ORDERS: {
      TRIP: {
        GET: "v2/companies/{company_id}/orders/{id}/?find_trip={trip}"
      },
      TRANSFER: {
        PATCH: "v2/companies/{company_id}/orders/{id}/?action=transfer_order",
      },
      NEW: {
        PATCH: "v2/companies/{company_id}/orders/{id}/?action=link_new_trip",
      },
      DELETE: {
        PATCH: "v2/companies/{company_id}/orders/{id}/?action=delete_order",
      },
      LIST:
        "v2/companies/{company_id}/orders/?view={view}&date={date}&facilities={facilities}&transport_classes={transport_classes}&fleet_providers={fleet_providers}&tags={tags}",
      PATCH:
        "v2/companies/{company_id}/orders/{id}/?view={view}&action={action}",
      CONTAINERS: {
        PATCH: "v2/companies/{company_id}/orders/{order_id}/containers/{container_id}/", //fleet_equipment
      },
      FACILITIES: {
        LIST: "v2/companies/{company_id}/orders/?view=facilities"
      },
      
      CONTROL: {
        POST: "v2/companies/{company_id}/orders_control/",
        RESUME: {
          LIST: "v2/companies/{company_id}/orders_control/?view=resume"
        },
        CATALOG: {
          LIST: "v2/companies/{company_id}/orders_control/facilities/{facility_id}/?limit={limit}&offset={offset}"
        },
        PROVIDERS: {
          LIST: "v2/companies/{company_id}/network/?view=approved_request",
          FACILITIES: {
            LIST: "v2/companies/{company_id}/network/{network_id}/?view=facilities",
          },
          PRODUCTS: {
            LIST: "v2/companies/{company_id}/network/{network_id}/?view=products",
          }
        }
      },
      DETAIL: {
        CONTAINER: {
          HOOK: {
            PATCH: "v2/companies/{company_id}/orders/{order_id}/?view=&action=hook_container&container_key={container_id}"
          },
          UNHOOK: {
            PATCH: "v2/companies/{company_id}/orders/{order_id}/?view=&action=unhook_container&container_key={container_id}"
          }
        }
      }
    },
    REQUISITIONSCONTROL: {
      PACKAGES: {
        PATCH: "v2/companies/{company_id}/oc_packages/{package_id}/",
        DELETE: "v2/companies/{company_id}/oc_packages/{package_id}/",
      },
      PALLETS: {
        PATCH: "v2/companies/{company_id}/oc_pallets/{pallet_id}/",
        SEARCH: {
          GET: "v2/companies/{company_id}/oc_pallets/?code={code}"
        },
        CATALOG: {
          LIST: "v2/companies/{company_id}/oc_pallets/?from_facility={from_facility}&to_facility={to_facility}"
        }
      },
      REQUISITIONS: {
        GET: "v2/companies/{company_id}/oc_requisitions/{requisition_id}/",
        POST: "v2/companies/{company_id}/oc_requisitions/",
        RESUME: {
          LIST: "v2/companies/{company_id}/oc_resume/?view=requisitions"
        },
        CATALOG: {
          LIST: "v2/companies/{company_id}/oc_requisitions/?from_facility={from_facility}&to_facility={to_facility}"
        }
      },
      ORDERS: {
        GET: "v2/companies/{company_id}/oc_orders/{order_id}/",
        POST: "v2/companies/{company_id}/oc_orders/",
        PATCH: "v2/companies/{company_id}/oc_orders/{order_id}",
        RESUME: {
          LIST: "v2/companies/{company_id}/oc_resume/?view=orders"
        },
        CATALOG: {
          LIST: "v2/companies/{company_id}/oc_orders/?from_facility={from_facility}&to_facility={to_facility}"
        }
      },
      TRIPS: {
        POST: "v2/companies/{company_id}/oc_trips/",
      },
    },
    ROLES: {
      POST: "v2/companies/{company_id}/roles/", // body -> description, color
      PATCH: "v2/companies/{company_id}/roles/{rol_id}/", // body -> description, color"
      PERMISSIONS: {
        PATCH:
          "v2/companies/{company_id}/roles/{rol_id}/detail/{permission_id}/", //body -> p_create, p_read, p_update, p_delete
      },
    },
    ROUTES: {
      ALIAS: {
        GET: "v2/companies/{company_id}/routes/?view={view}&alias={alias}",
      },
      CLASSES: {
        LIST:
          "v2/companies/{company_id}/transport_class/?view={view}&from_facility={from_facility}&to_facility={to_facility}",
      },
      CODE: {
        GET: "v2/companies/{company_id}/routes/?view={view}&code={code}",
      },
      DELETE: "v2/companies/{company_id}/routes/{route_id}/",
      DETAILS: {
        CHILDS: {
          DELETE:
            "v2/companies/{company_id}/routes/{route_id}/details/{row_id}",
          LIST: "v2/companies/{company_id}/routes/{route_id}/details/",
          PATCH:
            "v2/companies/{company_id}/routes/{route_id}/details/{row_id}/",
          POST: "v2/companies/{company_id}/routes/{route_id}/details/",
        },
        FILES: {
          DELETE:
            "v2/companies/{company_id}/routes/{route_id}/files/{file_id}/",
          LIST: "v2/companies/{company_id}/routes/{route_id}/files/",
        },
        GET: "v2/companies/{company_id}/routes/{route_id}",
        MAPS: {
          LIST: "v2/companies/{company_id}/routes/{route_id}/details?view=map",
        },
        NOMENCLATURES: {
          LIST: "v2/companies/{company_id}/routes/{route_id}/xml/",
        },
        TAGS: {
          DELETE: "v2/companies/{company_id}/routes/{route_id}/tags/{tag_id}/",
          LIST: "v2/companies/{company_id}/routes/{route_id}/tags/",
          POST: "v2/companies/{company_id}/routes/{route_id}/tags/",
        },
      },
      FACILITIES: {
        LIST: "v2/companies/{company_id}/facilities/?view={view}",
      },
      NETWORKFACILITIES: {
        LIST: "v2/companies/{company_id}/network_facilities/?view=grouped",
      },
      FILTERS: {
        LIST: "v2/companies/{company_id}/routes/?view={view}&group={group}",
      },
      LIST:
        "v2/companies/{company_id}/routes/?view={view}&limit={limit}&offset={offset}&from_facility={from_facility}&to_facility={to_facility}&tags={tags}&classes={classes}&search={search}",
      PATCH: "v2/companies/{company_id}/routes/{route_id}/",
      POST: "v2/companies/{company_id}/routes/",
      TAGS: {
        LIST: "v2/companies/{company_id}/tags/?type={type}",
      },
      ZONES: {
        CATALOG1: {
          LIST: "v2/companies/{company_id}/routes/{route_id}/control_zones/?type=5",
        }, 
        CATALOG2: {
          LIST: "v2/companies/{company_id}/routes/{route_id}/control_zones/?type=3",
        },
        CATALOG3: {
          LIST: "v2/companies/{company_id}/routes/{route_id}/control_zones/?type=2",
        },
        TYPE1: {
          LIST: "v2/companies/{company_id}/routes/{route_id}/zones/?type=5",
        },
        TYPE2: {
          LIST: "v2/companies/{company_id}/routes/{route_id}/zones/?type=3",
        },
        TYPE3: {
          LIST: "v2/companies/{company_id}/routes/{route_id}/zones/?type=2",
        },
        POST: "v2/companies/{company_id}/routes/{route_id}/zones/",
        PATCH: "v2/companies/{company_id}/routes/{route_id}/zones/{zone_id}/",
        DELETE: "v2/companies/{company_id}/routes/{route_id}/zones/{zone_id}/",
      }
    },
    SAT: {
      CFDI: {
        TIPOFACTURA: {
          LIST: "v1/catalogs/tipofactura/"
        },
        USOCFDI: {
          LIST: "v1/catalogs/usocfdi/"
        },
        MONEDAS: {
          LIST: "v1/catalogs/monedas/"
        },
        FORMADEPAGO: {
          LIST: "v1/catalogs/formadepago/"
        },
        METODODEPAGO: {
          LIST: "v1/catalogs/metodopago/"
        },
        CLAVEPRODSERVCPACOTADO: {
          LIST: "v1/catalogs/claveprodservcp/?view=cfdi"
        },
        CLAVEPRODSERVCP: {
          LIST: "v1/catalogs/claveprodservcp/"
        },
        CLAVEUNIDADPESOACOTADO: {
          LIST: "v1/catalogs/claveunidad/?view=cfdi"
        },
        CLAVEUNIDADPESO: {
          LIST: "v1/catalogs/claveunidad/"
        },
        IMPUESTO: {
          LIST: "v1/catalogs/impuesto/"
        },
        MOTIVOSCANCELACION: {
          LIST: "v1/catalogs/motivos_cancelacion/"
        },
        TIPOFACTOR: {
          LIST: "v1/catalogs/tipofactor/"
        },
        TASACUOTA: {
          LIST: "v1/catalogs/tasacuota/"
        }
      },
      TAXINFO: { 
        GET: "v2/companies/{company_id}/{request_type}/{trip_id}/cfdi/?action={action}",
        UPDATEPRODUCTS: {
          POST: "v2/companies/{company_id}/{request_type}/{trip_id}/cfdi/?action=updatePackage",
        },
        SEARCHPRODUCTS: {
          GET: "v2/companies/{company_id}/{request_type}/{trip_id}/cfdi/?action=get_products&search={search}",
        },
        CREATE: {
          POST: "v2/companies/{company_id}/{request_type}/{tripid}/cfdi/"
        },
        VALIDATION: {
          GET: "v2/companies/{company_id}/trips/{trip_id}/?cfdi_validation=ingreso"
        },
        OPERATOR: {
          GET: "v2/companies/{company_id}/fleets/{fleet_id}/operators/{id}/tax_info/{tax_info_id}/",
        },
        CANCELLATION: {
          GET: "v2/companies/{company_id}/{request_type}/{trip_id}/cfdi/?action=get_cancellation_details",
          CANCEL: {
            POST: "v2/companies/{company_id}/{request_type}/{trip_id}/cfdi/?action=cancel"
          }
        }
      },
      PERMISSIONS: {
        LIST: "v1/catalogs/tipopermiso/"
      },
      REGIMES: {
        LIST: "v1/catalogs/regimencapital/"
      },
      ADDRESS: {
        ZIPCODECHECK: {
          LIST: "v1/catalogs/main-catalog/?cp={cp}&id_orbi_pais={country}"
        },
        ZIPCODECHECKBYCOUNTRY: {
          LIST: "v1/catalogs/main-catalog/?cp={cp}&country={country}"
        },
        CATALOGS: {
          COUNTRY: {
            LIST: "v1/catalogs/pais/"
          },
        },
        FACILITY: {
          // LINK: {
          //   POST: "v2/companies/{company_id}/facilities/{facility_id}/tax_info/"
          // },
          ERASE: {
            DELETE: "v2/companies/{company_id}/facilities_tax_info/{facility_tax_info_id}/"
          },
          LIST: "v2/companies/{company_id}/tax_info/{tax_info_id}/?view=facilities"
        },
        VEHICLE:{
          // LINK: {
          //   POST: "v2/companies/{company_id}/facilities/{facility_id}/tax_info/"
          // },
          ERASE: {
            DELETE: "v2/companies/{company_id}/fleet_data_tax_info/{fleet_data_tax_info_id}/"
          },
          LIST: "v2/companies/{company_id}/tax_info/{tax_info_id}/?view=vehicles"
        },
        LIST: "v2/companies/{company_id}/tax_info/",
        CREATE: {
          POST: "v2/companies/{company_id}/tax_info/"
        },
        RETRIEVE: {
          GET: "v2/companies/{company_id}/tax_info/{tax_info_id}/" 
        },
        UPDATE: {
          PATCH: "v2/companies/{company_id}/tax_info/{tax_info_id}/" //link_facilities: [], link_vehicles: []
        },
        ERASE: {
          DELETE: "v2/companies/{company_id}/tax_info/{tax_info_id}/"
        }
      },
      CATALOGS: {
        CIUDAD: {
          LIST: "v1/catalogs/localidad/"
        },
        CODIGOPOSTAL: {
          LIST: "v1/catalogs/codigospostales/"
        },
        COLONIA: {
          LIST: "v1/catalogs/colonia/"
        },
        ESTADO: {
          LIST: "v1/catalogs/estados/"
        },
        PAIS: {
          LIST: "v1/catalogs/pais/"
        },
        REGIMENFISCAL: {
          LIST: "v1/catalogs/regimenfiscal/"
        },
        TIPOPERMISO: {
          LIST: "v1/catalogs/tipopermiso/"
        },
        ZONES: {
          LIST: "v1/public/time/zone/"
        },
        CLAVEPRODUCTO: {
          LIST: "v1/catalogs/claveprodservcp/"
        },
        CLAVEPRODUCTOBUSQUEDA: {
          LIST: "v1/catalogs/claveprodservcp/?&search={search}"
        },
        TIPOEMBALAJE: {
          LIST: "v1/catalogs/tipoembalaje/"
        },
        CLAVEPELIGROSO: {
          LIST: "v1/catalogs/materialpeligroso/"
        },
        CLAVEUNIDAD: {
          LIST: "v1/catalogs/claveunidad/"
        },
        CLAVEUNIDADPESO: {
          LIST: "v1/catalogs/claveunidadpeso/"
        }
      }
    },
    COMMANDCONTROL:{
      LIST: "v2/companies/{company_id}/main_control/?categories={categories}&facilities={facilities}&transport_classes={transport_classes}&fleet_providers={fleet_providers}&maneuver={maneuver}&base={base}&clients={clients}",
      FILTER: {
        GET: "v2/companies/{company_id}/user_filter_control/?filter_type={filter_type}",
        POST: "v2/companies/{company_id}/user_filter_control/",
        CATEGORIES: {
          LIST: "v2/companies/{company_id}/filter_categories/?filter_type={filter_type}&company_type={company_type}"
        },
      }
    },
    SHIPMENTS: {
      LT: {
        RESUME: {
          LIST: "v2/companies/{company_id}/orders/?view={view}&date={date}&facilities=&transport_classes=&fleet_providers=&tags="
        },
        APPOINTMENTS: {
          LIST: "v2/companies/{company_id}/orders/?view={view}&date={date}&facilities={facilities}&priority={priority}&status={status}&transport_classes={transport_classes}&cargo_owner={cargo_owner}"
        },
        FILTERS: {
          LIST: "v2/companies/{company_id}/cross_information/?start_date&end_date&view=filters"
        }
      }
    },
    SUSCRIPTIONS: {
      WALLET: {
        LIST: "v2/companies/{company_id}/subscriptions/?view=wallet",
      },
      PLANS: {
        LIST: "v2/companies/{company_id}/subscriptions/?view=plans",
      },
      STATEMENTS: {
        PDF: {
          GET: "v2/companies/{company_id}/subscriptions/?view=statement&month={month}&year={year}&action=download_xls"
        },
        XLS: {
          GET: "v2/companies/{company_id}/subscriptions/?view=statement&month={month}&year={year}&action=download_pdf"
        }
      },
      CATALOGSUBSCRIPTION: {
        LIST: "v2/companies/catalogs/subscription/"
      }
    },
    SYNC: {
      SUPPLEMENT1: {
        LIST: "v2/companies/{company_id}/sync/?type=9"
      }
    },
    TAGS: {
      LIST: "v2/companies/{company_id}/tags/",
      DATES: {
        LIST: "v2/companies/{company_id}/tags/?view=dates",
      },
      VALIDATION: {
        POST: "v2/companies/{company_id}/tag/"
      }
    },
    TATV2: {
      GLOBAL: {
        LIST: "v2/companies/{company_id}/tat_v2/?group=&view=global&start_date={start_date}&end_date={end_date}&from_facility={from_facility}&to_facility={to_facility}&fleet_provider={fleet_provider}&cargo_owner={cargo_owner}&uen={uen}&drv={drv}&route={route}&action={action}&order_tags={order_tags}&trip_tags={trip_tags}&base_tags={base_tags}&facilities={facilities}&transport_class={transport_class}"
      },
      DESGLOSE: {
        LIST: "v2/companies/{company_id}/tat_v2/?group=&view=desglose&start_date={start_date}&end_date={end_date}&from_facility={from_facility}&to_facility={to_facility}&fleet_provider={fleet_provider}&cargo_owner={cargo_owner}&uen={uen}&drv={drv}&route={route}&action={action}&order_tags={order_tags}&trip_tags={trip_tags}&base_tags={base_tags}&facilities={facilities}&transport_class={transport_class}"
      },
      CRITERIO: {
        LIST: "v2/companies/{company_id}/tat_v2/?group={group}&view=criterio&start_date={start_date}&end_date={end_date}&from_facility={from_facility}&to_facility={to_facility}&fleet_provider={fleet_provider}&cargo_owner={cargo_owner}&uen={uen}&drv={drv}&route={route}&operators={operators}&economic_numbers={economic_numbers}&lt_base={base}&action={action}&order_tags={order_tags}&trip_tags={trip_tags}&base_tags={base_tags}&facilities={facilities}&transport_class={transport_class}"
      }
    },
    TAT: {
      GLOBAL: {
        LIST: "v2/companies/{company_id}/tat/?group=&view=global&start_date={start_date}&end_date={end_date}&from_facility={from_facility}&to_facility={to_facility}&fleet_provider={fleet_provider}&cargo_owner={cargo_owner}&uen={uen}&drv={drv}&route={route}&action={action}&order_tags={order_tags}&trip_tags={trip_tags}&base_tags={base_tags}&facilities={facilities}&transport_class={transport_class}"
      },
      DESGLOSE: {
        LIST: "v2/companies/{company_id}/tat/?group=&view=desglose&start_date={start_date}&end_date={end_date}&from_facility={from_facility}&to_facility={to_facility}&fleet_provider={fleet_provider}&cargo_owner={cargo_owner}&uen={uen}&drv={drv}&route={route}&action={action}&order_tags={order_tags}&trip_tags={trip_tags}&base_tags={base_tags}&facilities={facilities}&transport_class={transport_class}"
      },
      CRITERIO: {
        LIST: "v2/companies/{company_id}/tat/?group={group}&view=criterio&start_date={start_date}&end_date={end_date}&from_facility={from_facility}&to_facility={to_facility}&fleet_provider={fleet_provider}&cargo_owner={cargo_owner}&uen={uen}&drv={drv}&route={route}&operators={operators}&economic_numbers={economic_numbers}&lt_base={base}&action={action}&order_tags={order_tags}&trip_tags={trip_tags}&base_tags={base_tags}&facilities={facilities}&transport_class={transport_class}"
      }
    },
    TRAFFIC: {
      LIST:
        "v2/companies/{company_id}/traffic/?limit={limit}&offset={offset}&previous_assemblies={previous_assemblies}&status={status}&transport_classes={transport_classes}&fleet_providers={fleet_providers}&priority={priority}&trip_tags={trip_tags}&order_tags={order_tags}&from_facilities={from_facilities}&to_facilities={to_facilities}&start_date={start_date}&end_date={end_date}&search={search}",
      AVAILABILITY: {
        PATCH: "v2/companies/{company_id}/fleets/{fleet_id}/assemblies/{assembly_id}/?action=set_availability"
      }  
    },
    TRANSPORTCLASS: {
      LIST: "v2/companies/{company_id}/transport_class/",
      POST: "v2/companies/{company_id}/transport_class/",
      DELETE: "v2/companies/{company_id}/transport_class/{id}",
      TAGS: {
        POST: "v2/companies/{company_id}/transport_class/{traclass_id}/tags/",
        DELETE:
          "v2/companies/{company_id}/transport_class/{traclass_id}/tags/{id}/",
        LIST: "v2/companies/{company_id}/transport_class/{traclass_id}/tags/",
      },
      FILTERS: {
        LIST: "v2/companies/{company_id}/transport_class/?view={view}",
      },
    },
    TRIPS: {
      DELETE: {
        PATCH: "v2/companies/{company_id}/trips/{id}/?action=delete_trip",
      },
      SIGNATURE: {
        GET: "v2/companies/{company_id}/trips/?signature={signature}",
      },
      LIST:
        "v2/companies/{company_id}/trips/?view={view}&appointment={appointment}&appointment_type={appointment_type}&status={status}&order_status={orderStatus}&base_tags={baseTags}&vehicle={vehicle}&fleet_providers={fleet_providers}&from_facilities={from_facilities}&to_facilities={to_facilities}&from_to_facilities={from_to_facilities}&limit={limit}&offset={offset}&search={search}&transport_classes={transport_classes}&action={action}&search_type={search_type}&timezone={timezone}",
      GET: "v2/companies/{company_id}/trips/{trip_id}/?view={view}",
      PATCH: "v2/companies/{company_id}/trips/{trip_id}/?action={action}",
      BALANCE: {
        GET: "v2/companies/{company_id}/trips/{trip_id}/?view=get_quote"
      },
      CANDIDATES: {
        GET: "v2/companies/{company_id}/trips/{trip_id}/?view=candidates&action=",
        PATCH: "v2/companies/{company_id}/trips/{trip_id}/?view=&action=transfer_events"
      },
      CONTROL: {
        UPLOAD: {
          POST: "v2/companies/{company_id}/trips_control/upload/"
        }
      },
      VALIDATIONS: {
        GET: "v2/companies/{company_id}/trips/{trip_id}/validations/?action={action}",
      }
    },
    USERS: {
      USERSTAGS: {
        LIST: "v2/companies/{company_id}/users/{user_id}/users_tags/",
      },
      USERFACILITIES: {
        LIST: "v2/companies/{company_id}/users/{user_id}/users_facilities/"
      }
    },
    UTILIZATION: {
      GLOBAL: {
        GET: "v2/companies/{company_id}/utilization/?view=global&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&base={base_tags}" 
      },
      DATE: {
        GET: "v2/companies/{company_id}/utilization/?view=custom_date&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&base={base_tags}" 
      },
      OFFENDERS: {
        GET: "v2/companies/{company_id}/utilization/?view=top_offenders&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&week={week}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&top_offenders_group={top_offenders_group}&base={base_tags}"
      },
      FOLIOS: {
        GET: "v2/companies/{company_id}/utilization/?view={view}&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&week={week}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&plates={plates}&economic_number={economic_number}&operators={operators}&base={base_tags}"
      },
      GRAPH: {
        GET: "v2/companies/{company_id}/utilization/?view={view}&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&week={week}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&plates={plates}&economic_number={economic_number}&operators={operators}&group_by={group_by}&base={base_tags}"
      },
    },
    UTILIZATIONV2: {
      GLOBAL: {
        GET: "v2/companies/{company_id}/utilization_v2/?view=global&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&base={base_tags}" 
      },
      DATE: {
        GET: "v2/companies/{company_id}/utilization_v2/?view=custom_date&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&base={base_tags}" 
      },
      DAILY: {
        GET: "v2/companies/{company_id}/utilization_v2/?view=daily&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&base={base_tags}" 
      },
      WEEKLY: {
        GET: "v2/companies/{company_id}/utilization_v2/?view=weekly&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&base={base_tags}" 
      },
      OFFENDERS: {
        GET: "v2/companies/{company_id}/utilization_v2/?view=top_offenders&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&week={week}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&top_offenders_group={top_offenders_group}&base={base_tags}"
      },
      FOLIOS: {
        GET: "v2/companies/{company_id}/utilization_v2/?view={view}&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&week={week}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&plates={plates}&economic_number={economic_number}&operators={operators}&base={base_tags}"
      },
      GRAPH: {
        GET: "v2/companies/{company_id}/utilization_v2/?view={view}&start_date={start_date}&end_date={end_date}&appointment_type={appointment_type}&week={week}&facilities={facilities}&from_facility={origin}&to_facility={destination}&trip_tags={trip_tags}&order_tags={order_tags}&transport_class={transport_class}&fleet_provider={fleet_provider}&supplement={supplement}&plates={plates}&economic_number={economic_number}&operators={operators}&group_by={group_by}&base={base_tags}"
      },
    }
  },
  RUTAS: {
    TIPOVEHICULO: {
      LIST: "v2/rutas/tipo_vehiculo/",
    },
  },
};

export const SERVER = getUrlsEnv('orbiback.').api;
export const FILES_SERVER = getUrlsEnv('orbiback.').files;
export const PATH = "/api/";
export const DOCS = "/api/docs";

export function getUrlsEnv(prefix = 'orbiback.') {
  var apiUrlEnv = window.location.protocol + "//" + prefix + window.location.hostname;
var filesUrlEnv = apiUrlEnv + "/api/v2/bridge/resource/private?path="
  var mqttUrlEnv = "mqtt." + prefix + window.location.hostname;
  var socketIoUrl = window.location.hostname.includes('qa.') ? 'https://zzezcmz2xi.us-west-2.awsapprunner.com/' : 'https://tmzbrygje2.us-west-2.awsapprunner.com/';
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    apiUrlEnv = "https://" + prefix + "qa.orbinetwork.com";
    filesUrlEnv =  "https://orbiback.qa.orbinetwork.com/api/v2/bridge/resource/private?path=";
    mqttUrlEnv = "mqtt.qa.orbinetwork.com";
    socketIoUrl = 'https://zzezcmz2xi.us-west-2.awsapprunner.com/';
  }
  return { api: apiUrlEnv, files: filesUrlEnv, mqtt: mqttUrlEnv, socket: socketIoUrl };
}
