import React from "react";
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";

import Layout from "./shared/layout/layout";

import FacilityCatalog from "./company/facility/facility-catalog";
import FacilityCreate from "./company/facility/facility-create";

import fleetCatalogView from "./company/fleet/fleet-catalog-view";
import fleetSummaryView from "./company/fleet/fleet-summary-view";
import FleetVehicleView from "./company/fleet/fleet-vehicle-view";
import FleetOperatorView from "./company/fleet/fleet-operator-view";
import FleetEquipmentView from "./company/fleet/fleet-equipment-view";

import RegisterAccountView from "./register/register-account-view";
import RegisterCompanyView from "./register/register-company-view";
import FleetVehicleProfile from "./company/fleet/fleet-vehicle-profile-view";
import UserData from "./company/user/user-data";
import FleetOperatorProfile from "./company/fleet/fleet-operator-profile-view";

import Home from "./company/home";
import CompanyData from "./company/company-data";
import FacilityProfileEdit from "./company/facility/facility-profile-edit";
import CompanyProfile from "./company/company-profile";
import facilityProfile from "./company/facility/facility-profile";
import UserProfile from "./company/user/user-profile";
import fleetEquipmentProfile from "./company/fleet/fleet-equipment-profile-view";
import UserCatalog from "./company/user/user-catalog-view";
import UserHistorical from "./company/user/user-historical-view";
import Login from "./register/login-view";
import CompanySelect from "./register/company-select-view";
import FacilitySelect from "./register/facility-select-view";
import NetworkCatalogView from "./company/network/network-catalog-view";
import assembleView from "./control/assemble/assemble-view";
import ContractDetailView from "./company/contract/contract-detail-view";
import ContractCatalogView from "./company/contract/contract-catalog-view";
import ShipmentSummaryView from "./control/shipment/shipment-summary-view";
import ShipmentDetailView from "./control/shipment/detail/shipment-detail-view";
import ShipmentOrderDetailView from "./control/shipment/order/order-detail-view";
import facilitiesSummaryView from "./control/facilities/facilities-summary-view";
import resourcesView from "./control/resources/resources-view";
import RouteCatalogView from "./company/routes/routes-catalog-view";
import RouteCatalogDetailsView from "./company/routes/details/route-catalog-details-view";
import PaymentsDashboard from "./company/payments/dashboard";
import AssembleShipmentView from "./control/assemble-shipment/assemble-shipment-view";
import AssembleShipmentResumeView from "./control/assemble-shipment/assemble-shipment-resume";
import gpsView from "./control/gps/gps-view";
import PaymentsBuy from "./company/payments/buy";
// import PaymentsSubscriptions from "./company/payments/subscriptions";
import landingView from "./register/landing-view";
import TrafficView from "./control/traffic/traffic-view";
import kpiView from "./control/kpi/kpi-view";
import TATView from "./reports/tat-view";
import TATViewV2 from "./reports/tat-v2-view";
import AlertReportView from "./reports/alerts-view";
import EquipmentsTrafficReport from "./reports/equipments-traffic-report";
import AvailabilityReportView from "./reports/availability-report";
import TATLogin from "./reports/tat-view-login";
import AlertDashboardView from "./company/alerts/alert-dashboard-view";
import productsCatalogView from "./company/products/products-catalog-view";
import registerNewProductView from "./company/products/register-new-product-view";
import productProfileView from "./company/products/products-profile-view";
import gpsActivityReport from "./reports/gps-activity-report";
import OrdersControlView from "./control/orders/orders-control-resume-view";
import OrdersControlCatalogView from "./control/orders/orders-control-catalog-view";
import mapIndicatorsView from "./reports/map-indicators/map-indicators-view";
import OrdersControlDetailView from "./control/orders/orders-control-detail-view";
import BillingFormView from "./company/billing/billing-form-view";
import TaxAddressFormView from "./company/billing/tax-address-form-view";
import UtilizationView from "./reports/utilization/utilization-view";
import UtilizationViewV2 from "./reports/utilization/utilization-v2-view";
import BillingCancellationView from "./company/billing/billing-cancellation-view";
import BillingReportView from "./reports/billing-report-view";
import ServicesReportView from "./reports/web-services-log/web-service-report-view";
import ServiceDetailView from "./reports/web-services-log/service-detail-view";
import TransactionDetailView from "./reports/web-services-log/transaction-detail-view";
import CommandControl from './company/command-control';
import platesActivityView from "./reports/plates-activity/plates-activity-view";
import TermsConditionsView from "./register/terms-conditions-view";
import BusinessIntelligenceView from './reports/business_intelligence/business-intelligence-view';
import businessIntelligenceProductivityView from "./reports/business_intelligence/business-intelligence-productivity-view";


const reload = () => window.location.reload();
export const routes = (
  <Router>
    <Layout>
      <Route exact path="/termsConditions" component={TermsConditionsView}/>
      <Route
        exact
        path="/index.html"
        render={() => {
          window.location.href = "/login";
        }}
      />
      <Route
        exact
        path="/"
        render={() => {
          window.location.href = "/login";
        }}
      />
      <Route
        exact
        path="/login.html"
        render={() => {
          window.location.href = "/login";
        }}
      />

      <Route
        exact
        path="/perfilPersonal.html"
        onEnter={() => <Redirect to="/user/profile" />}
      />
      <Route
        exact
        path="/registroCuenta.html"
        render={() => <Redirect to="/register/account" />}
      />
      <Route exact path="/catalogoUsuarios.html" onEnter={reload} />
      <Route exact path="/registroEmpresa.html" onEnter={reload} />

      <Route exact path="/login" component={Login} />
      <Route
        exact
        path="/company/reports/tat2"
        component={TATLogin}
      />
      <Route exact path="/login2" component={landingView} />
      <Route exact path="/company/select" component={CompanySelect} />
      <Route exact path="/facility/select" component={FacilitySelect} />
      <Route exact path="/company/facility" component={FacilityCatalog} />
      {/* <Route exact path="/company/facility/profile/" component={FacilityProfileContainer} /> */}
      <Route
        exact
        path="/company/facility/edit"
        component={FacilityProfileEdit}
      />
      <Route
        exact
        path="/company/facility/profile/:id"
        component={facilityProfile}
      />
      <Route exact path="/company/facility/create" component={FacilityCreate} />

      <Route exact path="/company/fleet" component={fleetCatalogView} />
      <Route exact path="/company/fleet/vehicle" component={FleetVehicleView} />
      <Route
        exact
        path="/company/fleet/vehicle/:vehicleId"
        component={FleetVehicleView}
      />
      <Route
        exact
        path="/company/fleet/vehicle/profile/:dataId"
        component={FleetVehicleProfile}
      />

      <Route
        exact
        path="/company/fleet/equipment"
        component={FleetEquipmentView}
      />
      <Route
        exact
        path="/company/fleet/equipment/:equipmentId"
        component={FleetEquipmentView}
      />
      <Route
        exact
        path="/company/fleet/equipment/profile/:dataId"
        component={fleetEquipmentProfile}
      />

      <Route exact path="/company/fleet/summary" component={fleetSummaryView} />

      <Route
        exact
        path="/company/fleet/operator"
        component={FleetOperatorView}
      />
      <Route
        exact
        path="/company/fleet/operator/:operatorId"
        component={FleetOperatorView}
      />
      <Route
        exact
        path="/company/fleet/operator/profile/:operatorId"
        component={FleetOperatorProfile}
      />

      <Route
        exact
        path="/company/controls/facility"
        component={facilitiesSummaryView}
      />

      <Route
        exact
        path="/company/controls/contract"
        component={ContractCatalogView}
      />
      
      <Route
        exact
        path="/company/controls/orders"
        component={OrdersControlView}
      />

      
      <Route
        exact
        path="/company/controls/order/detail/:id"
        component={OrdersControlCatalogView}
      />
      
      <Route
        exact
        path="/company/controls/order/orderdetail/:id"
        component={OrdersControlDetailView}
      />

      <Route
        exact
        path="/company/controls/contract/detail/:contractId"
        component={ContractDetailView}
      />

      <Route
        exact
        path="/company/control/resources"
        component={resourcesView}
      />

      <Route exact path="/company/network" component={NetworkCatalogView} />

      <Route
        exact
        path="/company/profile/:company"
        component={CompanyProfile}
      />
      <Route exact path="/company/data/:company" component={CompanyData} />

      <Route exact path="/register/account" component={RegisterAccountView} />
      <Route exact path="/register/company" component={RegisterCompanyView} />

      <Route exact path="/configurations" component={Home} />
      <Route exact path="/command" component={CommandControl} />

      <Route exact path="/company/user/" component={UserCatalog} />
      <Route exact path="/company/user/historical" component={UserHistorical} />
      <Route exact path="/company/products/" component={productsCatalogView} />
      <Route exact path="/company/product/" component={registerNewProductView} />
      <Route exact path="/company/product/:product" component={registerNewProductView} />
      <Route exact path="/company/product/profile/:product" component={productProfileView}/>
      <Route exact path="/user/profile/" component={UserProfile} />
      <Route exact path="/user/profile/:user" component={UserProfile} />
      <Route exact path="/user/data" component={UserData} />
      <Route exact path="/company/controls/kpi" component={kpiView} />
      <Route exact path="/company/controls/gpsActivity" component={gpsActivityReport} />
      <Route exact path="/company/reports/indicators" component={mapIndicatorsView} />
      <Route exact path="/company/controls/gps" component={gpsView} />
      <Route exact path="/company/controls/assemble" component={assembleView} />
      <Route exact path="/company/reports/platesActivity" component={platesActivityView} />
      <Route exact path="/company/reports/businessIntelligence" component={BusinessIntelligenceView} />
      <Route exact path="/company/reports/businessIntelligence/productivity/:configurationId" component={businessIntelligenceProductivityView} />
      <Route
        exact
        path="/company/controls/shipment/summary"
        component={ShipmentSummaryView}
      />
      <Route
        exact
        path="/company/reports/tat"
        component={TATViewV2} 
      />
      <Route
        exact
        path="/company/reports/tat_v1"
        component={TATView} 
      />
      <Route
        exact
        path="/company/reports/utilization"
        component={UtilizationViewV2}
      />
      <Route
        exact
        path="/company/reports/utilization_v1"
        component={UtilizationView}
      />
      <Route
        exact
        path="/company/reports/alerts"
        component={AlertReportView}
      />
      <Route
        exact
        path="/company/reports/equipments"
        component={EquipmentsTrafficReport}
      />
      <Route
        exact
        path="/company/reports/availability"
        component={AvailabilityReportView}
      />
      <Route
        exact
        path="/company/controls/billing"
        component={BillingReportView}
      />
      <Route
        exact
        path="/company/reports/services"
        component={ServicesReportView}
      />
      <Route
        exact
        path="/company/reports/services/:serviceId"
        component={ServiceDetailView}
      />
      <Route
        exact
        path="/company/reports/services/transactions/:transactionId"
        component={TransactionDetailView}
      />
      <Route
        exact
        path="/company/controls/shipment/detail/:facility"
        component={ShipmentDetailView}
      />
      <Route
        exact
        path="/company/controls/shipment/orderdetail/:order"
        component={ShipmentOrderDetailView}
      />
      <Route exact path="/company/routes" component={RouteCatalogView} />
      <Route
        exact
        path="/company/routes/route/details/:routeId"
        render={(props) => (
          <RouteCatalogDetailsView {...props} editMode={false} />
        )}
      />
      <Route
        exact
        path="/company/routes/route/edit/:routeId"
        render={(props) => (
          <RouteCatalogDetailsView {...props} editMode={true} />
        )}
      />
      <Route
        exact
        path="/company/routes/create"
        render={(props) => (
          <RouteCatalogDetailsView {...props} editMode={true} />
        )}
      />
      <Route 
        exact
        path="/company/alerts"
        component={AlertDashboardView}
      />
      
      <Route 
        exact
        path="/company/billing/:tripId"
        component={BillingFormView}
      />

      <Route 
        exact
        path="/company/billingcancellation/:tripId"
        component={BillingCancellationView}
      />
      
      <Route 
        exact
        path="/company/billingaddress/"
        component={TaxAddressFormView}
      />
      <Route 
        exact
        path="/company/billingaddress/:addressId"
        component={TaxAddressFormView}
      />

      <Route
        exact
        path="/company/payments"
        render={(props) => <PaymentsDashboard />}
      />
      <Route path="/company/payments/buy" render={(props) => <PaymentsBuy />} />
      {/* <Route
        exact
        path="/company/payments/subscriptions"
        render={(props) => <PaymentsSubscriptions />}
      /> */}
      <Route
        exact
        path="/company/controls/shipment/assemble"
        render={(props) => <AssembleShipmentView {...props}/>}
      />
      
      <Route
        exact
        path="/company/controls/shipment/assemble/summary"
        component={AssembleShipmentResumeView}
      />
      <Route
        exact
        path="/company/controls/traffic"
        render={(props) => <TrafficView {...props} />}
      />
    </Layout>
  </Router>
);
