import React from "react";
import { View, ViewTitle } from "../../shared/styled/view";
import styled from "styled-components";
import { getEntityItems, readEntities, readCatalog } from "../../redux/api";
import { connect } from "react-redux";

import Loader from "../../shared/icons/tat/loader";
import { cancelMultipleRequests, createEntity, deleteEntity } from "../../redux/api/actions";
import Button from "../../shared/controls/button";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import TextInput from "../../shared/controls/text-input";
import OptionSelectorSwitch from "../../shared/controls/option-selector-switch";

class BusinessIntelligenceView extends React.Component {

  state = {
    filterToDelete: null,
    maneuver: "productivity",
    errorMessage: '',
    nameInput: '',
    errorMessageBelow: '',
    newNameReportModal: false
  }

  componentDidMount() {
    document.addEventListener("keydown", this.cancelUpdate);

    this.loadBIConfigurations();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.cancelUpdate, true);
  }

  cancelUpdate = (e) => {
    if (e.key === "Escape" && this.props.isLoading) {
      this.props.cancelMultipleRequests();
    }
  }

  loadBIConfigurations = () => {
    this.props.getConfigurations({
      company_id: this.props.companyId
    });
  }

  // Utilizacion
  buildUtilizationView = () => {
    return this.props.configurations.filter(item => item.type === 2 ).map( (item, index) => {
      return(
        <CellConfigStyled
          key={`utilization-${index}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {item.name}

          <CellLinkStyle
            onClick={(e) => {
              e.stopPropagation();
            }}
          >Eliminar</CellLinkStyle>
        </CellConfigStyled>
      );
    });
  }

  // Productividad
  buildProductivityView = () => {
    return this.props.configurations.filter(item => item.type === 1 ).map( (item, index) => {
      return(
        <CellConfigStyled
          onClick={(e) => {
            e.stopPropagation();
            this.props.history.push(`/company/reports/businessIntelligence/productivity/${item.id}/`);
          }}
          key={`productivity-${index}`}
        >
          {item.name}

          <CellLinkStyle
            onClick={(e) => {
              e.stopPropagation();
              this.setState({filterToDelete: item.id})
            }}
          >Eliminar</CellLinkStyle>
        </CellConfigStyled>
      );
    });
  }

  deleteConfiguration = () => {

    this.props.deleteConfiguration({
      company_id: this.props.companyId,
      configuration_id: this.state.filterToDelete,
      isMultipleCancels: true
    },{
      alias: "BIBUSINESSINTELLIGENCE_DELETE",
      onSuccess: () => {
        this.setState({filterToDelete: null});
        this.loadBIConfigurations();
      },
      onError: (e) => {
        this.setState({filterToDelete: null, errorMessage: e.response?.data?.error || 'Ocurrió un error'})
      }
    });

  }

  createConfiguration = () => {

    this.setState({ errorMessageBelow: '' });

    this.props.createConfiguration({
      company_id: this.props.companyId,
      name: this.state.nameInput,
      type: 1,
      filter: {},
      isMultipleCancels: true
    },{
      alias: "BIBUSINESSINTELLIGENCE_CREATE",
      onSuccess: () => {
        this.setState({newNameReportModal: false, nameInput: ''});
        this.loadBIConfigurations();
      },
      onError: (e) => {
        this.setState({filterToDelete: null, errorMessageBelow: e.response?.data?.error || 'Ocurrió un error'})
      }
    });

  }

  render() {
    return (
      <View className="billing-report-view">
        <ViewTitle>
          {
            this.props.isLoading &&
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "#00000080",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1001,
                backdropFilter: "blur(3px)"
              }}
            >
              <div style={{display: "flex", alignItems: "center"}}>
                <Loader width="100px" height="100px"/>
                <div style={{color: "#fff", fontSize: "36px"}}>
                  Cargando
                </div>
              </div>
              <div style={{color: "#fff", fontSize: "30px"}}>
                Si desea cancelar la consulta, presione ESC
              </div>
            </div>
          }
          <div style={{flex: "1"}}>
            BI Inteligencia de Negocios
          </div>
          <Button
            settings={{
              type: "btn primary",
              text: "Crear",
              onClick: () => {
                if (this.state.maneuver === 'utilization') {
                  this.setState({errorMessage: 'Modulo en desarrollo'});
                } else {
                  this.setState({ newNameReportModal: true })   
                }
              },
            }}
          />
        </ViewTitle>
        
        <SelectorSwitchContent>
          <OptionSelectorSwitch
            value={this.state.maneuver}
            name="maneuver"
            onChange={(e) => {
              const { name, value } = e.target;
              this.setState({ [name]: value });
            }}
            items={[
              { description: "Productividad", value: "productivity" },
              { description: "Utilización", value: "utilization" }
            ]}
          />
        </SelectorSwitchContent>

        {
          this.props.isLoading ? <div/> :
          this.props.configurations.length === 0 ?
          <NoResultsStyled>
            No se encuentró ningun dato
            <LoadContentStyled>
              <Button
                settings={{
                  type: "btn primary small",
                  text: "Cargar",
                  onClick: () => this.loadBIConfigurations(),
                }}
              />
            </LoadContentStyled>
          </NoResultsStyled> : 
          <TableConfigStyled>
            { this.state.maneuver === 'productivity' ? this.buildProductivityView() : this.buildUtilizationView()}
          </TableConfigStyled>
        }
        
        <ConfirmDialog
          open={this.state.newNameReportModal}
          message="Nombra el nuevo reporte que deseas crear"
          contentObject={
            <div className="input-tagger-container">
              <TextInput
                label='Ingresa el nombre'
                type='text'
                value={this.state.nameInput}
                name='nameInput'
                onChange={(e) => {
                  const { name, value } = e.target;
                  this.setState({[name]: value});
                }}
              />
              {
                this.state.errorMessageBelow !== '' ? <ErrorMessageBelow>{`*${this.state.errorMessageBelow}`}</ErrorMessageBelow> : <></>
              }
            </div>
          }
          acceptText="Continuar"
          class="bi-business-intelligence-modal-name"
          closeAction={() => this.setState({newNameReportModal: false})}
          acceptAction={() => this.createConfiguration()}
          disabledAccept={this.state.nameInput === ''}
          isAcceptLoading={this.props.isCreatingLoading && this.state.errorMessageBelow === ''}
        />

        <ConfirmDialog
          open={this.state.filterToDelete !== null}
          title={'Eliminar filtro'}
          message="¿Deseas eliminar el filtro?"
          showCancel
          acceptText="Aceptar"
          closeAction={() => this.setState({filterToDelete: null})}
          acceptAction={() => this.deleteConfiguration()}
          isAcceptLoading={this.props.isDeletingLoading}
        />

        <ConfirmDialog
          open={this.state.errorMessage !== ''}
          title={'¡Error!'}
          message={`${this.state.errorMessage}`}
          acceptText="Aceptar"
          acceptAction={() => this.setState({errorMessage: ''})} 
          closeAction={() => this.setState({errorMessage: ''})} 
        />
        
      </View>
    );
  }
}

const mapStateToProps = (state) => {

  let isLoading = state?.api["COMPANIES.BIBUSINESSINTELLIGENCE"]?.status?.isFetching || false;
  let configurations = state?.api["COMPANIES.BIBUSINESSINTELLIGENCE"]?.items || [];

  let isDeletingLoading = state?.api["BIBUSINESSINTELLIGENCE_DELETE"]?.status?.isFetching || false;
  let isCreatingLoading = state?.api["BIBUSINESSINTELLIGENCE_CREATE"]?.status?.isFetching || false;

  return {
    isLoading,
    configurations,
    isDeletingLoading,
    isCreatingLoading,
    companyId: state.globalCatalog.session.company.id
  };
}

const mapDispatchToProps = (dispatch) => {
  dispatch(readEntities("COMPANIES.CATALOGS.TRIPSTATUS"));
  
  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    getConfigurations: (params, opts) => dispatch(readEntities("COMPANIES.BIBUSINESSINTELLIGENCE", params, opts)),
    deleteConfiguration: (params, opt) => dispatch(deleteEntity("COMPANIES.BIBUSINESSINTELLIGENCE", params, opt)),
    createConfiguration: (params, opt) => dispatch(createEntity("COMPANIES.BIBUSINESSINTELLIGENCE", params, opt))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessIntelligenceView);

const TableConfigStyled = styled.div`
  width: 100%;
  height: 600px;
  background-color: rgb(33, 42, 60);
  overflow-y: scroll;
  padding-left: 6px;
  padding-right: 6px;
`;

const CellConfigStyled = styled.div`
  display: flex;
  width: calc(100% - 20px);
  height: 60px;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: rgb(69, 84, 113);
  align-items: center;
  border: 7px;
  color: rgb(175, 200, 255);
  padding-left: 20px;
  cursor: pointer;
`;

const CellLinkStyle = styled.div`
  margin-left: 25px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

const SelectorSwitchContent = styled.div`
  width: 300px;
  margin-left: 50%;
  transform: translate(-50%);
  margin-top: 20px;
  margin-bottom: 30px;
`;

const NoResultsStyled = styled.div`
  text-align: center;
  color: white;
  font-size: 35px;
  margin-top: 80px;
`;

const LoadContentStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;

const ErrorMessageBelow = styled.div`
  font-size: 13px;
  color: red;
  font-weight: normal;
`;