import React, { useState, useMemo, useCallback, createRef } from "react";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Button from "../../shared/controls/button";
import Loader from "../../shared/icons/tat/loader";

export const Grid = (props) => {
  const gridRef = createRef();

  const [rowData, setRowData] = useState(props.rowData);
  
  const [colDefs, setColDefs] = useState([
    { headerName: 'Fecha de emisión', field: 'fecha_de_emision' },
    { headerName: 'Folio de viaje', field: 'folio_de_viaje', filter: true },
    { headerName: 'Razón Social del receptor', field: 'razon_social_del_receptor', filter: true },
    { headerName: 'RFC Receptor', field: 'rfc_receptor', filter: true },
    { headerName: 'Razón Social del emisor', field: 'razon_social_del_emisor', filter: true },
    { headerName: 'RFC Emisor', field: 'rfc_emisor', filter: true },
    { headerName: 'Importe', field: 'importe', aggFunc: 'sum', filter: "agNumberColumnFilter" },
    { headerName: 'Origen', field: 'origen', filter: true },
    { headerName: 'Cita Carga', field: 'cita_carga', filter: true },
    { headerName: 'Destino', field: 'destino', filter: true  },
    { headerName: 'Cita Descarga', field: 'cita_descarga', filter: true },
    { headerName: 'Ruta', field: 'ruta', filter: true },
    { headerName: 'Distancia Recorrida', field: 'distancia_recorrida', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Grupo expedición de orden', field: 'grupo_expedicion_de_orden' },
    { headerName: 'Expedición de orden', field: 'expedicion_de_orden' },
    { headerName: 'Nombre Operador', field: 'nombre_operador', filter: true },
    { headerName: 'Operador base', field: 'operador_base', filter: true },
    { headerName: 'Placa Vehículo', field: 'placa_vehiculo', filter: true },
    { headerName: 'Economico Vehículo', field: 'eco_vehiculo', filter: true },
    { headerName: 'Vehículo base', field: 'vehiculo_base', filter: true },
    { headerName: 'Folio fiscal (UUID)', field: 'folio_fiscal', filter: true },
    { headerName: 'Tipo', field: 'tipo', filter: true },
    { headerName: 'Estatus', field: 'estatus', filter: true },
    { headerName: 'Tiempo de anticipación de creación del viaje', field: 'tiempo_de_anticipacion_de_creacion_del_viaje', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de anticipación de asignación del viaje', field: 'tiempo_de_anticipacion_de_asignacion_del_viaje', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de anticipación de inicio del viaje', field: 'tiempo_de_anticipacion_de_inicio_del_viaje', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Puntualidad en origen', field: 'puntualidad_en_origen', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Adherencia cita carga', field: 'adherencia_cita_carga', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de ocio en origen', field: 'tiempo_de_ocio_en_origen', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de espera en origen', field: 'tiempo_de_espera_en_origen', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de retraso de transportista en origen', field: 'tiempo_de_retraso_de_transportista_en_origen', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de espera por retraso de transportista en origen', field: 'tiempo_de_espera_por_retraso_de_transportista_en_origen', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Estadía dentro origen sin espera', field: 'estadia_dentro_origen_sin_espera', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Estadía dentro origen con espera', field: 'estadia_dentro_origen_con_espera', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Estadía dentro y fuera origen', field: 'estadia_dentro_y_fuera_origen', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de transito meta', field: 'tiempo_de_transito_meta', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de transito sin espera destino', field: 'tiempo_de_transito_sin_espera_destino', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de transito con espera destino', field: 'tiempo_de_transito_con_espera_destino', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Adherencia tiempo de transito meta', field: 'adherencia_tiempo_de_transito_meta', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Puntualidad en destino', field: 'puntualidad_en_destino', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Adherencia cita descarga', field: 'adherencia_cita_descarga', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de ocio en destino', field: 'tiempo_de_ocio_en_destino', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de espera en destino', field: 'tiempo_de_espera_en_destino', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de retraso de transportista en destino', field: 'tiempo_de_retraso_de_transportista_en_destino', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Tiempo de espera por retraso de transportista en destino', field: 'tiempo_de_espera_por_retraso_de_transportista_en_destino', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Estadía dentro destino sin espera', field: 'estadia_dentro_destino_sin_espera', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Estadía dentro destino con espera', field: 'estadia_dentro_destino_con_espera', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'Estadía dentro y fuera destino', field: 'estadia_dentro_y_fuera_destino', aggFunc: 'sum', filter: 'agNumberColumnFilter' },
    { headerName: 'PDF Archivo', field: 'pdf_archivo' },
    { headerName: 'PDF Nombre', field: 'pdf_nombre' },
    { headerName: 'XML Archivo', field: 'xml_archivo' },
    { headerName: 'XML Nombre', field: 'xml_nombre' }
  ]);

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
      pinned: "left",
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      resizable: true,
      sortable: true
    };
  }, []);

  const onGridReady = useCallback((params) => {

    if (props.configuration['filter'] !== undefined && typeof props.configuration['filter'] === "object") {
      gridRef.current.api.setFilterModel(
        props.configuration['filter']
      );
    }

    if (props.configuration['columns'] !== undefined && typeof props.configuration['columns'] === "object") {

      let temp_columns = props.configuration['columns'];

      if (temp_columns.length === colDefs.length) {
        gridRef.current.api.setColumnDefs(
          temp_columns
        );
        return;
      }

      try {

        let currentColumns = colDefs.map( item => item.field);
        let currentColumnsSaved = props.configuration['columns'].map( item => item.field);
        let difference = currentColumns.filter(x => !currentColumnsSaved.includes(x));

        let newColumnsToAdd = [];

        colDefs.map( (item, index) => {
          if (difference.includes(item.field)) {
            newColumnsToAdd.push({
              index,
              column: item
            })
          }
        });

        newColumnsToAdd.map( item => {
          temp_columns.splice(item.index, 0, item.column);
        });

        gridRef.current.api.setColumnDefs(
          temp_columns
        );

      } catch (error) {
        console.error('Error setting columns definitions', error);

        gridRef.current.api.setColumnDefs(
          temp_columns
        );
      }
    }

  }, []);

  return (
    <div>
      <div
        className="ag-theme-alpine"
        style={{ height: 650, marginTop: 50, marginBottom: 50 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          enableCellTextSelection={true}
          groupDisplayType={"groupRows"}
          rowGroupPanelShow={"always"}
          groupDefaultExpanded={1}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          sideBar={true}
          enableCharts={true}
          enableRangeSelection={true}
          onGridReady={onGridReady}
        />
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 20
      }}>
        <Button
          settings={{
            disabled: props.isLoading,
            type: "btn primary",
            text: props.isLoading ? <Loader/> : "Guardar",
            onClick: () => {
              let config = {
                columns: gridRef.current.api.getColumnDefs(),
                filter: gridRef.current.api.getFilterModel()
              };

              if (props.setNewFilter) {
                props.setNewFilter(config);
              }
            }
          }}
        />
      </div>
    </div>
  );
}